import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import GetPageTitleTxt from "./GetPageTitleTxt";

const Dashboard = lazy(() => import('../Dashboard'));
const Sellerlist = lazy(() => import('../Sellerlist'));
const SellerProgressList = lazy(() => import('../SellerProgressList'));
const BuyerList = lazy(() => import('../BuyerList'));
const OrderList = lazy(() => import('../Orderlist'));
const CaptureEmail = lazy(() => import('../CaptureEmail'));
const FilterLogs = lazy(() => import('../FilterLogs'));
const QuoteLogs = lazy(() => import('../QuoteCalcLogs'));
const CityContent = lazy(() => import('../CityContent'));
const TagsList = lazy(() => import('../Tags'));
const SellerSequence = lazy(() => import('../SellerSequence'));
const PrintDownload = lazy(() => import('../PrintDownload'));
const JobsList = lazy(() => import('../JobsList'));
const ReviewList = lazy(() => import('../ReviewList'));
const SearchPageDynamic = lazy(() => import('../SearchPageDynamic'));
const GeoLocation = lazy(() => import('../GeoLocation'));
const Payments = lazy(() => import('../Payments'));
const Conversation = lazy(() => import('../Conversation'));
const Reporting = lazy(() => import('../Reporting'));
const AllRecordingList = lazy(() => import('../OrderRecordingList'));
const NotInterestedOrder = lazy(() => import('../NotInterestedOrder'));
const FlagOrderDetails = lazy(() => import('../FlagOrderDetails'));
const EmailTemplatesManage = lazy(() => import('../EmailTemplates'));
const DeletedArtistList = lazy(() => import('../DeletedArtistList'));
const SpamOrderJobList = lazy(() => import('../SpamOrderJobList'));
const PageNotFound = lazy(() => import('../PageNotFound'));
const CouponList = lazy(() => import('../CouponList'));
const HoldPaymentList = lazy(() => import('../HoldPaymentList'));
const SubscriptionList = lazy(() => import('../SubscriptionList'));
const SearchTermPages = lazy(() => import('../SearchTermPages'));
const GiftCardList = lazy(() => import('../GiftCardList'));
const ReferralList = lazy(() => import('../ReferralList'));
const SellerAllDetail = lazy(() => import('../SellerAllDetail'));
const OTPReport = lazy(() => import('../OTPReport'));
const SellerCountWithCity = lazy(() => import('../SellerCountWithCity'));
const BadgeList = lazy(() => import('../BadgeList'));
const TaskDashboard = lazy(() => import('../TaskDashboard'));
const BuyArtList = lazy(() => import('../BuyArtList'));
const BuyerConversation = lazy(() => import('../BuyerConversation'));
const OrderExport = lazy(() => import('../OrderExport'));
const NewJobList = lazy(() => import('../NewJobList'));

const fallBack = (<div className="page-loader-container"><span className="loading__anim"></span></div>);

const routes = [
  {
    path: "/",
    component: <Suspense fallback={fallBack}><Dashboard /></Suspense>,
    module_code: 'dashboard'
  },
  {
    path: "dashboard",
    component: <Suspense fallback={fallBack}><Dashboard /></Suspense>,
    module_code: 'dashboard'
  },
  {
    path: "sellerlist",
    component: <Suspense fallback={fallBack}><Sellerlist /></Suspense>,
    module_code: 'seller'
  },
  {
    path: "buyerlist",
    component: <Suspense fallback={fallBack}><BuyerList /></Suspense>,
    module_code: 'buyer'
  },
  {
    path: "orderlist",
    component: <Suspense fallback={fallBack}><OrderList /></Suspense>,
    module_code: 'order'
  },
  {
    path: "captured-email",
    component: <Suspense fallback={fallBack}><CaptureEmail /></Suspense>,
    module_code: 'captured-email'
  },
  {
    path: "filter-logs",
    component: <Suspense fallback={fallBack}><FilterLogs /></Suspense>,
    module_code: 'filter_logs'
  },
  {
    path: "review-list",
    component: <Suspense fallback={fallBack}><ReviewList /></Suspense>,
    module_code: 'review-list'
  },
  {
    path: "quote-calc-logs",
    component: <Suspense fallback={fallBack}><QuoteLogs /></Suspense>,
    module_code: 'quote-calc-logs'
  },
  {
    path: "city-content",
    component: <Suspense fallback={fallBack}><CityContent /></Suspense>,
    module_code: 'city-content'
  },
  {
    path: "seller-resequence",
    component: <Suspense fallback={fallBack}><SellerSequence /></Suspense>,
    module_code: 'seller-resequence'
  },
  {
    path: "print-download",
    component: <Suspense fallback={fallBack}><PrintDownload /></Suspense>,
    module_code: 'print-download'
  },
  {
    path: "tags",
    component: <Suspense fallback={fallBack}><TagsList /></Suspense>,
    module_code: 'tags'
  },
  {
    path: "jobslist",
    component: <Suspense fallback={fallBack}><JobsList /></Suspense>,
    module_code: 'jobslist'
  },
  {
    path: "seller-progress-list",
    component: <Suspense fallback={fallBack}><SellerProgressList /></Suspense>,
    module_code: 'seller-progress-list'
  },
  {
    path: "search-page-content",
    component: <Suspense fallback={fallBack}><SearchPageDynamic /></Suspense>,
    module_code: 'search-page-content'
  },
  {
    path: "geo-location",
    component: <Suspense fallback={fallBack}><GeoLocation /></Suspense>,
    module_code: 'geo-location'
  },
  {
    path: "payments",
    component: <Suspense fallback={fallBack}><Payments /></Suspense>,
    module_code: 'payment'
  },
  {
    path: "payments/:id",
    component: <Suspense fallback={fallBack}><Payments /></Suspense>,
    module_code: 'payment'
  },
  {
    path: "conversations",
    component: <Suspense fallback={fallBack}><Conversation /></Suspense>,
    module_code: 'conversations'
  },
  {
    path: "reporting",
    component: <Suspense fallback={fallBack}><Reporting /></Suspense>,
    module_code: 'reporting'
  },
  {
    path: "order-recording-list",
    component: <Suspense fallback={fallBack}><AllRecordingList /></Suspense>,
    module_code: 'order-recording-list'
  },
  {
    path: "not-interested-order-list",
    component: <Suspense fallback={fallBack}><NotInterestedOrder /></Suspense>,
    module_code: 'not-interested-order-list'
  },
  {
    path: "flag-order-details-list",
    component: <Suspense fallback={fallBack}><FlagOrderDetails /></Suspense>,
    module_code: 'flag-order-details-list'
  },
  {
    path: "email-template-list",
    component: <Suspense fallback={fallBack}><EmailTemplatesManage /></Suspense>,
    module_code: 'email-template-list'
  },
  {
    path: "deleted-artist-list",
    component: <Suspense fallback={fallBack}><DeletedArtistList /></Suspense>,
    module_code: 'deleted-artist-list'
  },
  {
    path: "spam-order-job-list",
    component: <Suspense fallback={fallBack}><SpamOrderJobList /></Suspense>,
    module_code: 'spam-order-job-list'
  },
  {
    path: "coupon-list",
    component: <Suspense fallback={fallBack}><CouponList /></Suspense>,
    module_code: 'coupon-list'
  },
  {
    path:"hold-payment-list",
    component: <Suspense fallback={fallBack}><HoldPaymentList /></Suspense>,
    module_code: 'hold-payment-list'
  },
  {
    path:"subscription-list",
    component: <Suspense fallback={fallBack}><SubscriptionList /></Suspense>,
    module_code: 'subscription-list'
  },
  {
    path: "search-term-pages",
    component: <Suspense fallback={fallBack}><SearchTermPages /></Suspense>,
    module_code: 'search-term-pages'
  },
  {
    path: "gift-card-list",
    component: <Suspense fallback={fallBack}><GiftCardList /></Suspense>,
    module_code: 'gift-card-list'
  },
  {
    path: "referral-user-list",
    component: <Suspense fallback={fallBack}><ReferralList /></Suspense>,
    module_code: 'referral-user-list'
  },
  {
    path: "otp-report",
    component: <Suspense fallback={fallBack}><OTPReport /></Suspense>,
    module_code: 'otp-report'
  },
  // {
  //   path: "otp-report/:id",
  //   component: <Suspense fallback={fallBack}><OTPReport /></Suspense>,
  //   module_code: 'otp-report'
  // },
  {
    path: "seller-detail",
    component: <Suspense fallback={fallBack}><SellerAllDetail /></Suspense>,
    module_code: 'seller-detail'
  },
  {
    path: "seller-detail/:id",
    component: <Suspense fallback={fallBack}><SellerAllDetail /></Suspense>,
    module_code: 'seller-detail'
  },
  {
    path: "seller-count-by-country",
    component: <Suspense fallback={fallBack}><SellerCountWithCity /></Suspense>,
    module_code: 'seller-count-by-country'
  },
  {
    path: "badge-list",
    component: <Suspense fallback={fallBack}><BadgeList /></Suspense>,
    module_code: 'badge-list'
  },
  {
    path: "task-dashboard",
    component: <Suspense fallback={fallBack}><TaskDashboard /></Suspense>,
    module_code: 'task-dashboard'
  },
  {
    path: "buy-art-list",
    component: <Suspense fallback={fallBack}><BuyArtList /></Suspense>,
    module_code: 'buy-art-list'
  },
  {
    path: "buyer-conversation",
    component: <Suspense fallback={fallBack}><BuyerConversation /></Suspense>,
    module_code: 'buyer-conversation'
  },
  {
    path: "order-export",
    component: <Suspense fallback={fallBack}><OrderExport /></Suspense>,
    module_code: 'order-export'
  },
  {
    path: "new-job-list",
    component: <Suspense fallback={fallBack}><NewJobList /></Suspense>,
    module_code: 'new-job-list'
  },
];

const AppRouter = (props) => {
  const { login_user_data } = props;
  var getPathName = window.location.href.split('/')[3];
  var result = GetPageTitleTxt(`/${getPathName}`);
  document.querySelector('title').textContent = result;
  
  return (
    <Routes>
      {routes.map(singleRoute => {
        const { path, component, module_code, ...otherProps } = singleRoute;
        const role = login_user_data.modules.filter(r => r.module_code === module_code)[0];
        const enableRoute = role && role.read === '1' || login_user_data.full_access === '1' || module_code === 'dashboard';
        return (
          <Route
            exact
            key={path}
            path={`/${path}`}
            element={enableRoute ? component : <PageNotFound />}
            {...otherProps}
          />
        );
      })}
    </Routes>
  );
}

export default AppRouter;